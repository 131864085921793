import React, { FormEvent, useState, useEffect, useRef } from 'react';
import { Button, SvgIcon } from '@ui-elements';
import { scroller } from 'react-scroll';
import { useSearchStore } from '../search-provider';
import styles from './index.module.scss';
import { SortOptions } from '../../models';

const LOCAL_STORAGE_KEY = 'recentSearches';

const SearchBar: React.FC = () => {
  const { searchData, getSuggestions, selectedSort } = useSearchStore();
  const [isQuerySearch, setQuerySearch] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [suggestedWords, setSuggestedWords] = useState<string[] | null>(null);
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const searchListRef = useRef<HTMLDivElement>(null);

  const loadRecentSearches = (): string[] => {
    const savedSearches = localStorage.getItem(LOCAL_STORAGE_KEY);
    return savedSearches ? JSON.parse(savedSearches) : [];
  };

  const saveRecentSearches = (searches: string[]) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(searches));
  };

  useEffect(() => {
    setRecentSearches(loadRecentSearches());
  }, []);

  const updateRecentSearches = (query: string) => {
    const currentSearches = loadRecentSearches();
    const updatedSearches = [
      query,
      ...currentSearches.filter((item) => item !== query),
    ];
    const limitedSearches = updatedSearches.slice(0, 3);
    setRecentSearches(limitedSearches);
    saveRecentSearches(limitedSearches);
  };

  const handleDeleteSearch = (word: string) => {
    const updatedSearches = recentSearches.filter((item) => item !== word);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedSearches));
    setRecentSearches(updatedSearches);
    saveRecentSearches(updatedSearches);
  };

  const searchSubmit = async (form: FormEvent<HTMLFormElement>) => {
    form.preventDefault();
    if (searchText.trim() !== '') {
      await searchData(searchText, [], '1', SortOptions.mostRelevant, true);
      scroller.scrollTo('result-container', {
        delay: 100,
        offset: -100,
        smooth: true,
      });
      setSuggestedWords(null);
      setQuerySearch(false);
      updateRecentSearches(searchText);
      updateUrlComponent(searchText);
    }
  };

  const onSearchTextChange = async (value: string) => {
    setSearchText(value);
  };

  const handleItemClick = async (value: string) => {
    setSearchText(value);
    await searchData(value, null, '1', selectedSort, false);
    scroller.scrollTo('result-container', {
      delay: 100,
      offset: -100,
      smooth: true,
    });
    setSuggestedWords(null);
    setQuerySearch(false);
    updateRecentSearches(value);
    updateUrlComponent(value);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      searchListRef.current &&
      !searchListRef.current.contains(event.target as Node)
    ) {
      setSuggestedWords(null);
    }
  };

  const handleClearButton = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    setSearchText('');
    setSuggestedWords(null);
  };

  const updateUrlComponent = (query: string) => {
    if (!isQuerySearch) {
      const params = new URLSearchParams(window.location.search);
      params.set('query', encodeURIComponent(query));
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${params}`
      );
    }
  };

  useEffect(() => {
    if (searchText.trim() !== '') {
      const delayDebounceFn = setTimeout(async () => {
        const results = await getSuggestions(searchText);
        if (!isQuerySearch) {
          setSuggestedWords(results);
        }
      }, 100);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchText]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      handleClickOutside(event);
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [searchListRef]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const query = params.get('query');

    if (query && !searchText) {
      setQuerySearch(true);
      setSearchText(decodeURIComponent(query));
      handleItemClick(decodeURIComponent(query));
    }
  }, [setSuggestedWords]);

  return (
    <div className={styles.search}>
      <div className={styles.searchContainer}>
        <div className={styles.searchContent}>
          <div className={styles.searchForm}>
            <form onSubmit={(e) => searchSubmit(e)}>
              <input
                placeholder="What can we help you find?"
                name="search"
                type="text"
                maxLength={80}
                value={searchText}
                onChange={(e) => onSearchTextChange(e.target.value)}
                onFocus={() => setSuggestedWords(recentSearches)}
              />
              {searchText && (
                <div
                  className={styles.clearBtn}
                  onClick={(e) => handleClearButton(e)}
                >
                  <SvgIcon type="close" size={1.6} color="#1637A0" />
                </div>
              )}
              <Button nativeType="submit" type="primary" buttonSize="large">
                <SvgIcon type="search" size={2} color="#ffffff" />
              </Button>
            </form>
          </div>
          <div className={styles.searchList} ref={searchListRef}>
            {suggestedWords?.map((word, index) => (
              <div className={styles.searchItemContainer} key={index}>
                <div
                  className={styles.searchItem}
                  onClick={() => handleItemClick(word)}
                  style={{
                    color: recentSearches.includes(word)
                      ? '#406DBA'
                      : '#646566',
                  }}
                >
                  {word}
                  {recentSearches.includes(word) && (
                    <span
                      className={styles.deleteButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteSearch(word);
                      }}
                    >
                      &times;
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
