import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { SvgIcon, Loading } from '@ui-elements';
import { SearchViewModel } from '../../../models/search-model';
import EditorPicks from './EditorPicks';
import { useSearchStore } from '../../search-provider';
import { scroller } from 'react-scroll';
import { useIsMobile } from '~/common/hooks/use-is-mobile';

interface SearchWithResultProps {
  props: SearchViewModel;
}

const SearchWithResult: React.FC<SearchWithResultProps> = ({ props }) => {
  const {
    searchTerm,
    searchData,
    selectedFilters,
    contentSearchResults,
    selectedSort,
    isSearchLoading,
    isNewPage,
  } = useSearchStore();
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [visibleTags, setVisibleTags] = useState<string[]>([]);
  const [categoryView] = useState(1);
  const itemsPerPage = 12;
  const [currentPage, setCurrentPage] = useState(1);
  const { containerRef, isMobile } = useIsMobile();
  const handlePageChange = async (newPage: number) => {
    try {
      setSelectedTag(null);
      scroller.scrollTo('result-container', {
        delay: 100,
        offset: -100,
        smooth: true,
      });
      await searchData(
        searchTerm,
        selectedFilters,
        newPage.toString(),
        selectedSort,
        false
      );
      setCurrentPage(newPage);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const arraysAreEqual = (arr1: any[], arr2: any[]) => {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  };

  const allCategories = useMemo(() => {
    const uniqueTags = Array.from(
      new Set(contentSearchResults?.Hits?.map((item) => item.PageType))
    );
    return ['All', ...uniqueTags];
  }, [contentSearchResults]);

  const filteredItems = useMemo(() => {
    return selectedTag
      ? contentSearchResults?.Hits?.filter(
          (item) => item.PageType === selectedTag
        )
      : contentSearchResults?.Hits;
  }, [selectedTag, contentSearchResults]);

  const totalPages = useMemo(
    () =>
      Math.ceil(
        (contentSearchResults?.TotalItemCount as number) / itemsPerPage
      ) || 0,
    [contentSearchResults, itemsPerPage]
  );
  const paginatedItems = useMemo(() => {
    const res = filteredItems;
    return res;
  }, [currentPage, filteredItems, itemsPerPage]);

  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const highlightSearchTerm = (text, term) => {
    if (!text || !term) return text;
    const words = term.split(' ');
    const regex = new RegExp(`\\b(${words.join('|')})\\b`, 'gi');
    return text.replace(regex, '<b>$&</b>');
  };

  const truncateAndHighlightText = (text, term, maxLength) => {
    const truncatedText = truncateText(text, maxLength);
    return highlightSearchTerm(truncatedText, term);
  };

  useEffect(() => {
    if (isNewPage) {
      setCurrentPage(1);
    }
  }, [isNewPage]);

  useEffect(() => {
    const startIdx = (categoryView - 1) * 4;
    const endIdx = startIdx + 4;
    const filteredTags = allCategories.filter((tag) => tag !== undefined);
    const newVisibleTags = filteredTags.slice(startIdx, endIdx);

    if (!arraysAreEqual(newVisibleTags, visibleTags)) {
      setVisibleTags(newVisibleTags as []);
    }
  }, [categoryView, allCategories]);

  return (
    <div ref={containerRef} id="with-result" className={styles.withResult}>
      <div className={styles.categories}>
        {visibleTags.map((tag, index) => (
          <button
            key={index}
            onClick={() => setSelectedTag(tag === 'All' ? null : tag)}
            className={
              tag === selectedTag || (tag === 'All' && selectedTag === null)
                ? styles.active
                : ''
            }
          >
            {tag}
          </button>
        ))}
      </div>
      <div className={styles.resultBody}>
        {isSearchLoading && <Loading height="100%" />}
        {!isSearchLoading && (
          <div className={styles.resultView}>
            <div
              className={`${styles.itemContainer} ${
                selectedTag ? styles.fadeOut : ''
              }`}
            >
              {paginatedItems?.map((item, index) => (
                <a
                  key={index}
                  href={item.Url}
                  target={'_self'}
                  rel="noreferrer"
                  className={styles.item}
                >
                  <div className={styles.tag}>{item.PageType}</div>
                  {item.ImageSrc ? (
                    <div className={styles.imageHolder}>
                      <img src={item.ImageSrc} alt={item.Heading} />
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className={styles.content}>
                    <div
                      className={styles.contentTitle}
                      dangerouslySetInnerHTML={{
                        __html: !isMobile()
                          ? truncateText(item.Heading, 100)
                          : truncateText(item.Heading, 85),
                      }}
                    />
                    <div
                      className={styles.description}
                      dangerouslySetInnerHTML={{
                        __html: !isMobile()
                          ? truncateAndHighlightText(
                              item.Description,
                              searchTerm,
                              160
                            )
                          : truncateAndHighlightText(
                              item.Description,
                              searchTerm,
                              90
                            ),
                      }}
                    />
                    <span className={styles.timeShared}>
                      {item.ReadingTime && (
                        <>&nbsp;|&nbsp; {item.ReadingTime}</>
                      )}
                    </span>
                  </div>
                </a>
              ))}
            </div>
            <div className={styles.paginationContainer}>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <SvgIcon type="chevronLeft" size={1} color="#323334" />
              </button>
              {Array.from({ length: totalPages }, (_, index) => {
                const pageNumber = index + 1;
                if (
                  (currentPage <= 3 && pageNumber <= 5) ||
                  (currentPage >= totalPages - 2 &&
                    pageNumber >= totalPages - 4) ||
                  (pageNumber >= currentPage - 2 &&
                    pageNumber <= currentPage + 2)
                ) {
                  return (
                    <button
                      key={pageNumber}
                      onClick={() => handlePageChange(pageNumber)}
                      className={
                        currentPage === pageNumber ? styles.activePage : ''
                      }
                    >
                      {pageNumber}
                    </button>
                  );
                }
                return null;
              })}
              {totalPages > 5 && (
                <>
                  {currentPage < totalPages - 2 && (
                    <span className={styles.ellipsis}>...</span>
                  )}
                  {currentPage < totalPages && (
                    <button onClick={() => handlePageChange(totalPages)}>
                      {totalPages}
                    </button>
                  )}
                </>
              )}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <SvgIcon type="chevronRight" size={1} color="#323334" />
              </button>
            </div>
          </div>
        )}
        <EditorPicks {...props} />
      </div>
    </div>
  );
};

export default SearchWithResult;
