/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import groupBy from 'lodash.groupby';
import styles from './index.module.scss';
import { AccordionItem, Button, ButtonTypeEnum } from '@ui-elements';
import { ResultsData, SearchViewModel } from '../../../models/search-model';

const EditorPicks: React.FC<SearchViewModel> = ({
  mainAd,
  otherAds,
  picksBanner,
}) => {
  return (
    <div id="editor-picks" className={styles.picksContainer}>
      <div className={styles.mainAd}>
        {mainAd?.map((item, index) => (
          <a href={item.url} key={index}>
            <div className={styles.bigAds}>
              <img src={item.image} alt={item.adTitle} />
              <div className={styles.innerContainerImage}>
                <h2 className={styles.adTitle}>{item.adTitle}</h2>
                <Button
                  href={item.url}
                  target={item.target}
                  className={styles.cta}
                  buttonWidth="full-width"
                  type={item.buttonType?.toLocaleLowerCase() as ButtonTypeEnum}
                >
                  {item.buttonText}
                </Button>
              </div>
            </div>
          </a>
        ))}
      </div>

      <div className={styles.editorContainer}>
        {picksBanner && picksBanner?.length >= 1 ? (
          <p className={styles.label}>Editors Picks</p>
        ) : (
          <></>
        )}
        {picksBanner?.map((item, index) => (
          <a
            key={index}
            href={item.url}
            target={'_blank'}
            rel="noreferrer"
            className={styles.adItem}
          >
            <div className={styles.adImageHolder}>
              <img src={item.image} alt={item.title} />
            </div>
            <div className={styles.adContent}>
              <div className={styles.adTag}>{item.type}</div>
              <div
                className={styles.adTitle}
                dangerouslySetInnerHTML={{ __html: item.title as string }}
              />
              <span className={styles.adTimeShared}>
                {item.datePosted}
                {item.timeRead && <>&nbsp;|&nbsp; {item.timeRead}</>}
              </span>
            </div>
          </a>
        ))}
      </div>
      <div className={styles.miniAds}>
        {otherAds?.map((item, index) => (
          <a href={item.url} key={index}>
            <div className={styles.minis}>
              <img src={item.image} alt={item.buttonText} />
              <div className={styles.innerContainerImage}>
                <span className={styles.adTitle}>{item.adTitle}</span>
                <Button
                  data-field="ctaButton"
                  href={item.url}
                  target={item.target}
                  className={styles.cta}
                  type={item.buttonType?.toLocaleLowerCase() as ButtonTypeEnum}
                >
                  {item.buttonText}
                </Button>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default EditorPicks;
