import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import { Button, Loading, SvgIcon } from '@ui-elements';
import { FilterOptions, SortOptions } from '../../models/search-model';
import { SearchWithNoResult, SearchWithResult } from '../search-result';
import { useIsMobile } from '~/common/hooks/use-is-mobile';
import { useSearchStore } from '../search-provider';
import digitalData from '~/common/services/data-layer-service';

const FilterPopup = ({
  displayOptions,
  onClose,
  isMobile,
  showFilterPopup,
}) => {
  const mobileFilterRef = useRef<HTMLDivElement>(null);
  const { searchTerm, searchData, selectedSort, selectedFilters } =
    useSearchStore();
  const [selectedFilter, setSelectedFilters] = useState<string[]>(
    selectedFilters || []
  );
  const handleCheckboxChange = (value) => {
    if (selectedFilter.includes(value)) {
      setSelectedFilters(selectedFilter.filter((filter) => filter !== value));
    } else {
      setSelectedFilters([...selectedFilter, value]);
    }
  };
  const handleClearAll = () => {
    setSelectedFilters([]);
  };
  const handleApplyClick = async () => {
    try {
      await searchData(searchTerm, selectedFilter, '1', selectedSort, true);
      onClose();
    } catch (error) {
      console.error('Error during onApply:', error);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        mobileFilterRef.current &&
        !mobileFilterRef.current.contains(event.target)
      ) {
        onClose();
      }
    }
    if (showFilterPopup) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showFilterPopup, onClose]);

  return !isMobile() ? (
    <div
      className={`${styles.filterPopup} ${!showFilterPopup ? styles.open : ''}`}
    >
      <div className={styles.column}>
        <h3>Result Type</h3>
        <div className={styles.resultType}>
          {displayOptions.map((option) => (
            <label key={option} className={styles.checkBoxLabel}>
              <input
                type="checkbox"
                value={option}
                checked={selectedFilter.includes(option)}
                onChange={() => handleCheckboxChange(option)}
              />
              {option}
            </label>
          ))}
        </div>
      </div>
      <div className={styles.filterBtn}>
        <Button onClick={handleApplyClick}>Apply</Button>
        <Button type="bordered" onClick={handleClearAll}>
          Clear All
        </Button>
      </div>
      <div className={styles.closeButton} onClick={() => onClose()}>
        <SvgIcon type="close" size={1.5} color="#646566" />
      </div>
    </div>
  ) : (
    <div
      className={`${cn(styles.filterMobile, 'full-device-width')} ${
        !showFilterPopup ? styles.open : ''
      }`}
    >
      <div ref={mobileFilterRef} className={styles.filterContainer}>
        <h3>Filter</h3>
        <div className={styles.column}>
          <div className={styles.resultType}>
            <h4>Result Type</h4>
            {displayOptions.map((option) => (
              <label key={option} className={styles.checkBoxLabel}>
                <input
                  type="checkbox"
                  value={option}
                  checked={selectedFilter.includes(option)}
                  onChange={() => handleCheckboxChange(option)}
                />
                {option}
              </label>
            ))}
          </div>
        </div>
        <div className={styles.filterBtn}>
          <Button buttonWidth="full-width" onClick={handleApplyClick}>
            Apply
          </Button>
          <Button
            buttonWidth="full-width"
            type="bordered"
            onClick={handleClearAll}
          >
            Clear All
          </Button>
        </div>
        <div className={styles.closeButton} onClick={() => onClose()}>
          <SvgIcon type="close" size={2} color="#646566" />
        </div>
      </div>
    </div>
  );
};

const SearchContents = () => {
  const { searchTerm, contentSearchResults, adsData, isSearchLoading } =
    useSearchStore();
  const [showSortPopup, setShowSortPopup] = React.useState(false);
  const [showFilterPopup, setShowFilterPopup] = React.useState(false);
  const sortOptions = Object.values(SortOptions);
  const filterOptions = Object.values(FilterOptions);
  const { containerRef, isMobile } = useIsMobile();
  const [itemsPerPage] = useState(12);
  const prevSearchTerm = useRef(searchTerm);
  const [prevFilterResults, setPrevFilterResults] = useState<{
    [key: string]: number;
  }>({});

  useEffect(() => {
    digitalData.pushWithCommonData({
      event: '_pageLoaded',
    });
  }, []);

  useEffect(() => {
    if (isSearchLoading) {
      setShowSortPopup(false);
      setShowFilterPopup(false);
      return;
    }
    if (searchTerm) {
      window['pushDigitalData'](
        {
          event: '_searchComplete',
          searchProperties: {
            keyword: searchTerm,
            type: 'site',
            resultsCount: contentSearchResults?.TotalItemCount,
          },
        },
        true
      );
    }
  }, [contentSearchResults?.TotalItemCount, isSearchLoading, searchTerm]);

  useEffect(() => {
    if (searchTerm !== prevSearchTerm.current) {
      prevSearchTerm.current = searchTerm;
      const defaultPageTypes: { [key: string]: number } = {};
      setPrevFilterResults(contentSearchResults?.PageTypes || defaultPageTypes);
    }
  }, [searchTerm]);

  const handleSortClick = () => {
    setShowSortPopup((prev) => !prev);
    setShowFilterPopup(false);
  };

  const handleSortPopupClose = () => {
    setShowSortPopup(false);
  };

  const handleFilterClick = () => {
    setShowFilterPopup((prev) => !prev);
    setShowSortPopup(false);
  };

  const handleFilterPopupClose = () => {
    setShowFilterPopup(false);
  };

  const filterResults = filterOptions.map((option) => {
    let filteredData;

    if (searchTerm === prevSearchTerm.current) {
      const pageTypes = prevFilterResults;
      const count = pageTypes ? pageTypes[option] || 0 : 0;
      filteredData = `${option} (${count})`;
    } else {
      const pageTypes = contentSearchResults?.PageTypes;
      const count = pageTypes ? pageTypes[option] || 0 : 0;
      filteredData = `${option} (${count})`;
    }
    return filteredData;
  });

  return (
    <div
      ref={containerRef}
      id="result-container"
      className={styles.outerContainer}
    >
      {contentSearchResults !== null ? (
        <div className={styles.resultsFor}>
          <p className={styles.resultsForText}>
            {contentSearchResults ? 'Results for' : 'No Results for'}
          </p>
          <h3 className={styles.resultsForTitle}>{searchTerm}</h3>
          <div className={styles.resultContainer}>
            <div className={styles.resultCount}>
              <p>
                <b>
                  {(contentSearchResults?.TotalItemCount as number) >= 1
                    ? Math.min(
                        ((contentSearchResults?.CurrentPage as number) - 1) *
                          itemsPerPage +
                          1,
                        contentSearchResults?.TotalItemCount as number
                      )
                    : `0`}
                </b>{' '}
                -{' '}
                <b>
                  {Math.min(
                    (contentSearchResults?.CurrentPage as number) *
                      itemsPerPage,
                    contentSearchResults?.TotalItemCount as number
                  )}
                </b>{' '}
                of <b>{contentSearchResults?.TotalItemCount}</b> results
              </p>
              <div className={styles.filterBtn}>
                <button onClick={handleFilterClick}>Filter</button>
              </div>
            </div>
          </div>
          {showFilterPopup && (
            <FilterPopup
              showFilterPopup={showFilterPopup}
              displayOptions={filterResults}
              onClose={handleFilterPopupClose}
              isMobile={isMobile}
            />
          )}
          {(contentSearchResults?.TotalItemCount as number) > 0 ? (
            <SearchWithResult props={adsData} />
          ) : (
            <SearchWithNoResult props={adsData} />
          )}
        </div>
      ) : (
        <div className={styles.landingPage}>
          <Loading height="100%" />
        </div>
      )}
    </div>
  );
};

export default SearchContents;
