import React, { useState } from 'react';
import { PageLayout } from '~/common/components';
import { SearchViewModel } from './models';
import SearchBar from './components/search-bar';

import { SearchStore } from './components/search-provider';
import SearchContents from './components/search-content';

const Search = (props: SearchViewModel) => {
  return (
    <div id="search-page">
      <SearchStore {...props}>
        <PageLayout>
          <SearchBar />
          <SearchContents />
        </PageLayout>
      </SearchStore>
    </div>
  );
};

export default Search;
